<template>
  <div class="aquapark" id="aquapark-info">
    <div class="aquapark__banner">
      <img src="@/assets/icons/banner.png"/>
    </div>
    <div class="aquapark__container _container">
      <h1 class="aquapark__title main-title">{{ $t("nav.hawaii") }}</h1>
      <div class="aquapark__body">
          <div class="aquapark__desc">
            <p class="aquapark__desc__title">ОБ АКВАПАРКЕ</p>
            <p v-html="aquapark.aquapark[0].description[$lang]" class="aquapark__desc__text"></p>
          </div>
          <p class="aquapark__desc__title">Прайс</p>
          <div class="aquapark__prices aquapark-prices">
            <div class="aquapark-prices__items">
              <div class="aquapark-prices__item">
                <div class="aquapark-prices__name">
                  {{ aquapark.aquaprice.left.title_ru }}
                </div>
                <div
                  class="aquapark-prices__info"
                  v-for="(priceInfo, i) in aquapark.aquaprice.left.items"
                  :key="i"
                >
                  <div class="aquapark-prices__time">
                    {{ priceInfo.title_ru }}
                    <span class="aquapark-prices__line"></span>
                  </div>
                  <div class="aquapark-prices__price">
                    {{ priceInfo.price }}
                  </div>
                </div>
              </div>
              <div class="aquapark-prices__item">
                <div class="aquapark-prices__name">
                  {{ aquapark.aquaprice.right.title_ru }}
                </div>
                <div
                  class="aquapark-prices__info"
                  v-for="(priceInfo, i) in aquapark.aquaprice.right.items"
                  :key="i"
                >
                  <div class="aquapark-prices__time">
                    {{ priceInfo.title_ru }}
                    <span class="aquapark-prices__line"></span>
                  </div>
                  <div class="aquapark-prices__price">
                    {{ priceInfo.price }}
                  </div>
                </div>
              </div>
            </div>
            <div class="aquapark-prices__content">
              <div
                class="aquapark-prices__block main-text"
                v-html="aquapark.aquaprice.desc_ru"
              ></div>
            </div>
          </div>
          <p class="aquapark__desc__title">График работы</p>
          <div class="aquapark__schedule aquapark-schedule">
            <h2 class="aquapark-schedule__title">{{ $t("main.graphic") }}:</h2>
            <ul class="aquapark-schedule__list">
              <li v-for="schedule in aquapark.shedule" :key="schedule.id">
                <span class="aquapark-schedule__icon"
                  ><img src="@/assets/icons/clock.svg" alt=""
                /></span>
                {{ schedule.description[$lang] }}
              </li>
            </ul>
          </div>
          <p class="aquapark__desc__title">Меню</p>
          <div class="aquapark__menu aquapark-menu">
            <div class="aquapark-menu__content">
              <div class="aquapark-menu__text" v-html="aquapark.menu[0].description[$lang]">
              </div>

              <ul
                class="aquapark-menu__list"
                v-for="menu in aquapark.menu"
                :key="menu.id"
              >
                <li>
                  <a
                    :href="
                      'https://admin.hawaii.kz/' + menu.file_common
                    "
                    download
                  >
                    <span class="aquapark-menu__icon"
                      ><img src="@/assets/icons/file.svg" alt=""
                    /></span>
                    <p style="color: #209E2F">{{ menu.open_menu[$lang] }}</p>
                  </a>
                </li>
                <!-- <li>
                  <a
                    :href="
                      'https://admin.hawaii.kz/' + menu.file_florida
                    "
                    download
                  >
                    <span class="aquapark-menu__icon"
                      ><img src="@/assets/icons/file.svg" alt=""
                    /></span>
                    {{ menu.open_menu_florida[$lang] }}
                  </a>
                </li> -->
                <li>
                  <a
                    :href="'https://admin.hawaii.kz/' + menu.file_aloha"
                    download
                  >
                    <span class="aquapark-menu__icon"
                      ><img src="@/assets/icons/file.svg" alt=""
                    /></span>
                    <p style="color: #209E2F">{{ menu.open_menu_aloha[$lang] }}</p>
                  </a>
                </li>
              </ul>
            </div>
            <swiper
              :slidesPerView="1"
              :pagination="{ clickable: true }"
              class="aquapark-menu__swiper"
            >
              <swiper-slide
                v-for="aquaparkSlide in aquapark.menugal"
                class="_ibg"
                :key="aquaparkSlide.id"
              >
                <img
                  :src="'https://admin.hawaii.kz/' + aquaparkSlide.img"
                  alt=""
                />
              </swiper-slide>
            </swiper>
          </div>
          <p class="aquapark__desc__title">ФОТОГАЛЕРЕЯ / ВИДЕО</p>
          <div class="aquapark__gallery aquapark-gallery">
            <swiper
              class="aquapark-gallery__swiper"
              :effect="'coverflow'"
              :slidesPerView="'auto'"
              :loop="true"
              :breakpoints="breakpoints"
              :speed="500"
              :navigation="photoNavigation"
              :modules="modules"
            >
              <swiper-slide
                v-for="photo in aquapark.apbiggal"
                class="_ibg"
                :key="photo.id"
              >
                <img
                  :src="'https://admin.hawaii.kz/' + photo.img"
                  alt=""
                />
              </swiper-slide>
            </swiper>
            <div class="photo-gallery-swiper-button-prev navigation-prev">
              <img src="@/assets/icons/arrow.svg" alt="" />
            </div>
            <div class="photo-gallery-swiper-button-next navigation-next">
              <img src="@/assets/icons/arrow.svg" alt="" />
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabItem from "@/components/TabItem";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, {
  Pagination,
  Navigation,
  EffectFade,
  EffectCoverflow,
} from "swiper";

import TabBlock from "@/components/TabBlock";
import { mapState, mapActions } from "vuex";
import AccordionComponent from "@/components/AccordionComponent";
import AccordionItem from "@/components/AccordionItem";

SwiperCore.use([Pagination, Navigation, EffectFade, EffectCoverflow]);

export default {
  components: {
    TabBlock,
    TabItem,
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [EffectCoverflow, Pagination],
    };
  },
  data() {
    return {
      selectedAquaparkTab: this.$t("main.aboutAqua"),
      photoNavigation: {
        nextEl: ".photo-gallery-swiper-button-next",
        prevEl: ".photo-gallery-swiper-button-prev",
      },
      videoNavigation: {
        nextEl: ".aquapark-videos-swiper-button-next",
        prevEl: ".aquapark-videos-swiper-button-prev",
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
          effect: "slide",
          allowTouchMove: true,
        },
        767: {
          effect: "slide",
          slidesPerView: 2,
          spaceBetween: 15,
        },
        991: {
          effect: "slide",
          slidesPerView: 3,
          spaceBetween: 20,
          centeredSlides: "false",
        },
        1366: {
          allowTouchMove: false,
          centeredSlides: "true",
          coverflowEffect: {
            stretch: 100,
            depth: 90,
            scale: 0.4,
            rotate: 0,
            modifier: 1,
          },
        },
      },
      showMore: false,
    };
  },
  computed: {
    ...mapState(["aquapark"]),
  },
  created() {
    this.fetchAquapark();
  },
  methods: {
    ...mapActions(["fetchAquapark"]),
    selectAquaparkTab(tab) {
      this.selectedAquaparkTab = tab;
    },
  },
};
</script>

<style lang="scss">
.aquapark {
  background: #E8F1F3;
  padding: 65px 0;
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media(max-width:768px){
    gap: 25px;
  }
  &__body{
    display: flex;
    flex-direction: column;
    gap: 60px;
    @media(max-width:768px){
      gap: 40px;
    }
  }
  &__desc{
    display: flex;
    flex-direction: column;
    gap: 50px;
    text-align: center;
    @media(max-width:768px){
        gap: 20px;
    }
    &__title{
      font-size: 35px;
      color: #4745B0;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      @media(max-width:768px){
            font-size: 20px !important;
        }
    }
  }
  @media (max-width: 991.98px) {
    padding: 45px 0;
  }
  @media (max-width: 479.98px) {
    padding: 32px 0;
  }
  &__banner{
    margin: 0 auto;
    width: 1660px;
    @media(max-width:768px){
      width: 95%;
    }
    img{
      width: 100%;
      aspect-ratio: 2.6;
    }
  }
  &__title {
    color: #256ed8;
    margin: 0 0 130px 0;

    &:after {
      background-image: url("@/assets/icons/title-icon.svg");
    }

    @media (max-width: 991.98px) {
      margin: 0 0 107px 0;
    }
    @media (max-width: 479.98px) {
      margin: 0 0 63px 0;
    }
  }

  &__content {
    @media (min-width: 991.98px) {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      justify-content: space-between;
      gap: 0 40px;
    }
  }

  &__info {
    flex: 0 1 584px;
    font-weight: 600;
    font-size: 18px;
    line-height: 138.9%;
    letter-spacing: 0.02em;
    color: #2c3131;
    transition: height 0.35s ease, opacity 0.35s ease;
    @media (max-width: 991.98px) {
      max-width: 585px;
      margin: 0 auto;
    }
    @media (max-width: 767.98px) {
      font-size: 14px;
      height: 152px;
      overflow: hidden;
      text-overflow: ellipsis;

      &._show {
        overflow: auto;
        height: 100%;
        transition: max-height 0.5s ease 0s;
      }
    }

    p {
      &:not(:last-child) {
        margin: 0 0 16px 0;
      }
    }
  }

  &__more {
    display: none;
    @media (max-width: 991.98px) {
      display: block;
      font-weight: 600;
      font-size: 20px;
      line-height: 110%;
      letter-spacing: 0.02em;
      text-decoration-line: underline;
      color: #256ed8;
      margin: 25px auto 0;
    }
    @media (max-width: 767.98px) {
      font-size: 16px;
      margin: 16px auto 0;
    }
  }

  &__items {
    margin: 16px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: 16px 5px;
  }

  &__item {
    display: flex;
    align-items: center;
    flex: 1 1 49%;
    text-align: left;
  }

  &__icon {
    margin: 0 12px 0 0;
  }

  &__swiper,
  .aquapark-menu__swiper {
    width: 536px;
    height: 600px;
    margin: 0;
    @media (max-width: 1366.98px) {
      width: 436px;
      height: 500px;
    }
    @media (max-width: 991.98px) {
      margin: 0 auto;
    }
    @media (max-width: 767.98px) {
      width: 306px;
      height: 333px;
      margin: 0 auto 23px;
    }

    @media (max-width: 479.98px) {
      width: 206px;
      height: 233px;
    }

    .swiper-slide {
      border: 3px solid #256ed8;
      border-radius: 60px;
      height: 501px;
      overflow: hidden;
      @media (max-width: 1366.98px) {
        height: 401px;
      }
      @media (max-width: 767.98px) {
        height: 306px;
        border-radius: 15px;
        border-width: 2px;
      }
      @media (max-width: 479.98px) {
        height: 206px;
      }
    }

    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 45.83px;
      @media (max-width: 767.98px) {
        bottom: 0;
      }
    }

    .swiper-pagination-bullet {
      width: 8.33px;
      height: 8.33px;
      margin: 0 12.5px !important;
      background: #b3b3bb;
      opacity: 1;

      &-active {
        background: #256ed8;
      }

      @media (max-width: 767.98px) {
        margin: 0 6.25px !important;
        width: 4.17px;
        height: 4.17px;
      }
    }
  }
}

.aquapark-prices {
  background: #ffffff;
  border: 3px solid #256ed8;
  border-radius: 60px;
  padding: 80px 52px 80px 56px;
  @media (max-width: 991.98px) {
    display: flex;
    flex-direction: column;
    padding: 52px 34px;
  }
  @media (max-width: 767.98px) {
    border: 2px solid #256ed8;
    border-radius: 15px;
  }
  @media (max-width: 479.98px) {
    padding: 24px 16px;
  }

  &__block {
    &:not(:last-child) {
      margin: 0 0 24px 0;
      @media (max-width: 479.98px) {
        margin: 0 0 16px 0;
      }
    }
  }

  &__title {
    margin: 0 0 16px 0;
    font-weight: 600 !important;
    @media (max-width: 479.98px) {
      margin: 0 0 12px 0;
    }
  }

  &__list {
    padding: 0 0 0 8px;

    li {
      list-style: inside;

      &:not(:last-child) {
        margin: 0 0 8px 0;
      }

      &::marker {
        color: #045495;
      }
    }
  }

  &__items {
    margin: 0 0 32px 0;
    @media (min-width: 991.98px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 0 100px;
    }
    @media (max-width: 991.98px) {
      margin: 0 0 24px 0;
    }
    @media (max-width: 479.98px) {
      margin: 0 0 16px 0;
    }
  }

  &__item {
    flex: 1 1 50%;
    @media (max-width: 991.98px) {
      &:not(:last-child) {
        margin: 0 0 35px 0;
      }
    }
    @media (max-width: 479.98px) {
      &:not(:last-child) {
        margin: 0 0 24px 0;
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow: hidden;

    &:not(:last-child) {
      margin: 0 0 24px 0;
      @media (max-width: 479.98px) {
        margin: 0 0 8px 0;
      }
    }
  }

  &__name {
    font-weight: 700;
    font-size: 36px;
    line-height: 136.2%;
    letter-spacing: 0.02em;
    color: #ff6b6b;
    margin: 0 0 24px 0;
    @media (max-width: 991.98px) {
      text-align: center;
      margin: 0 0 18px 0;
      font-size: 28px;
    }
    @media (max-width: 479.98px) {
      margin: 0 0 12px 0;
      font-size: 20px;
    }
  }

  &__time,
  &__price {
    font-size: 20px;
    line-height: 135%;
    letter-spacing: 0.02em;
    @media (max-width: 479.98px) {
      font-size: 16px;
    }
  }

  &__time {
    font-weight: 600;
    color: #2c3131;
  }

  &__line {
    position: absolute;
    display: inline-block;
    width: 100%;
    bottom: 7px;
    height: 1px;
    border-bottom: 1px dashed #a2a6bb;
  }

  &__price {
    background: #ffffff;
    z-index: 2;
    font-weight: 700;
    color: #00bed6;
  }
}

.aquapark-schedule {
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 137.5%;
    letter-spacing: 0.02em;
    color: #2c3131;
    margin: 0 0 32px 0;
    @media (max-width: 767.98px) {
      font-size: 16px;
      margin: 0 0 16px 0;
    }
  }

  &__list li {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 138.8888%;
    letter-spacing: 0.02em;
    color: #2c3131;

    &:not(:last-child) {
      margin: 0 0 16px 0;
    }

    @media (max-width: 767.98px) {
      font-size: 14px;
      &:not(:last-child) {
        margin: 0 0 12px 0;
      }
    }
  }

  &__icon {
    width: 35px;
    height: 35px;
    margin: 0 16px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767.98px) {
      width: 24px;
      height: 24px;
      margin: 0 5px 0 0;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.aquapark-menu {
  display: flex;
  justify-content: space-between;
  gap: 0 20px;
  @media (max-width: 991.98px) {
    flex-direction: column-reverse;
    gap: 0;
  }

  &__content {
    @media (min-width: $tablet + px) {
      flex: 0 1 624px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 138.9%;
    letter-spacing: 0.02em;
    color: #2c3131;
    margin: 0 0 24px 0;

    text-align: justify;
    p {
      &:not(:last-child) {
        margin: 0 0 16px 0;
      }
      text-align: justify;
    }

    @media (max-width: 767.98px) {
      width: 343px;
      height: 133px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      font-size: 14px;
      margin: 0 0 8px 0;
    }
  }

  &__list {
    @media (max-width: $mobile + px) {
      margin: 25px 0 0 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 13px 0 0 0;
    }
    li {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      line-height: 135%;
      letter-spacing: 0.02em;
      color: #256ed8;

      &:not(:last-child) {
        margin: 0 0 16px 0;
      }

      @media (max-width: 767.98px) {
        font-size: 16px;
        justify-content: center;
        &:not(:last-child) {
          margin: 0 0 12px 0;
        }
      }

      a {
        display: flex;
        align-items: center;
      }
    }
  }

  &__more {
    display: none;
    @media (max-width: 767.98px) {
      margin: 12px auto 16px;
      display: block;
      font-weight: 600;
      font-size: 16px;
      line-height: 137.5%;
      letter-spacing: 0.02em;
      text-decoration-line: underline;
      color: #256ed8;
    }
    @media (max-width: 479.98px) {
      margin: 8px auto 12px;
    }
  }

  &__icon {
    width: 35px;
    height: 35px;
    margin: 0 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767.98px) {
      width: 24px;
      height: 24px;
      img {
        width: 16px;
        height: 21px;
      }
    }
  }
}

.aquapark-gallery {
  position: relative;
  margin: 0 0 130px 0;
  @media (max-width: 1366.98px) {
    padding: 0 80px;
  }
  @media (max-width: 767.98px) {
    margin: 0 0 65px;
  }
  @media (max-width: 479.98px) {
    padding: 0 50px;
  }

  &__swiper {
    height: 362px;
    @media (min-width: 1366.98px) {
      width: 1082px;
    }
    @media (max-width: 1366.98px) {
      height: 240px;
    }
    @media (max-width: 479.98px) {
      height: 175px;
    }
    @media (max-width: 389.98px) {
      height: 155px;
    }
    @media (max-width: 320.98px) {
      height: 135px;
    }

    .swiper-slide {
      overflow: hidden;
      height: 100%;
      transition: all 0.3s ease;
      border-radius: 30px;
      @media (min-width: 1366px) {
        width: 566px;
        top: 120px;
        opacity: 0.5;
      }
      @media (max-width: 479.98px) {
        border-radius: 15px;
        border: 2px solid #256ed8;
      }

      &-active {
        opacity: 1;
        border: none;
        box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.25) !important;
        @media (min-width: 1366.98px) {
          transform: translate3d(0px, -120px, 0px) rotateX(0deg) rotateY(0deg)
            scale(1) !important;
        }

        @media (max-width: 479.98px) {
          border-radius: 15px;
          border: 2px solid #256ed8;
        }
      }
    }
  }

  .navigation-prev {
    @media (min-width: 1366.98px) {
      transform: translateY(90px) !important;
    }
  }

  .navigation-next {
    @media (min-width: 1366.98px) {
      transform: translateY(90px) rotate(180deg) !important;
    }
  }

  .photo-gallery-swiper-button-next,
  .photo-gallery-swiper-button-prev {
    background: #256ed8;
  }
}

.aquapark-videos {
  position: relative;
  @media (max-width: 1366.98px) {
    padding: 0 80px;
  }
  @media (max-width: 479.98px) {
    padding: 0 50px;
  }

  &__swiper {
    height: 362px;
    @media (min-width: 1366.98px) {
      width: 1082px;
    }
    @media (max-width: 1366.98px) {
      height: 240px;
    }
    @media (max-width: 479.98px) {
      height: 175px;
    }
    @media (max-width: 389.98px) {
      height: 155px;
    }
    @media (max-width: 320.98px) {
      height: 135px;
    }

    .swiper-slide {
      overflow: hidden;
      height: 100%;
      transition: all 0.3s ease;
      border-radius: 30px;
      @media (min-width: 1366px) {
        width: 566px;
        top: 120px;
        opacity: 0.5;
      }
      @media (max-width: 479.98px) {
        border-radius: 15px;
        border: 2px solid #256ed8;
      }

      &-active {
        opacity: 1;
        border: none;
        box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.25) !important;
        @media (min-width: 1366.98px) {
          transform: translate3d(0px, -120px, 0px) rotateX(0deg) rotateY(0deg)
            scale(1) !important;
        }

        @media (max-width: 479.98px) {
          border-radius: 15px;
          border: 2px solid #256ed8;
        }
      }
    }
  }

  .aquapark-videos-swiper-button-next,
  .aquapark-videos-swiper-button-prev {
    background: #256ed8;
  }

  .navigation-prev {
    @media (min-width: 1366.98px) {
      transform: translateY(90px) !important;
    }
  }

  .navigation-next {
    @media (min-width: 1366.98px) {
      transform: translateY(90px) rotate(180deg) !important;
    }
  }
}
</style>
