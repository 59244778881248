<template>
  <div class="faq" id="faq" ref="faq">
    <div class="faq__container _container">
      <h1 class="faq__title main-title">{{ $t("nav.question") }}</h1>
      <div class="faq__body">
        <Accordion>
          <AccordionItem
            v-for="faqItem in faq.slice(0, 7)"
            :key="faqItem.id"
            class="faq__item"
          >
            <template #accordion-header>
              <div class="faq__question">
                <div class="faq__text">
                  {{ faqItem.question[$lang] }}
                </div>
                <span class="faq__icon">
                  <img src="@/assets/icons/orange-arrow.svg" alt="" />
                </span>
              </div>
            </template>
            <template #accordion-content>
              <div class="faq__answer" style="text-align: justify">
                {{ faqItem.answer[$lang] }}
              </div>
            </template>
          </AccordionItem>
          <transition
            name="accordion"
            @enter="start"
            @after-enter="end"
            @before-leave="start"
            @after-leave="end"
          >
            <div v-if="showMore">
              <AccordionItem
                class="faq__item"
                v-for="faqItem in faq.slice(7, faq.length + 1)"
                :key="faqItem.id"
              >
                <template #accordion-header>
                  <div class="faq__question">
                    <div class="faq__text">
                      {{ faqItem.question[$lang] }}
                    </div>
                    <span class="faq__icon">
                      <img
                        src="@/assets/icons/orange-arrow.svg"
                        alt=""
                      />
                    </span>
                  </div>
                </template>
                <template #accordion-content>
                  <div class="faq__answer" style="text-align: justify">
                    {{ faqItem.answer[$lang] }}
                  </div>
                </template>
              </AccordionItem>
            </div>
          </transition>
        </Accordion>
        <button class="faq__button" @click="showMore = !showMore">
          <span v-if="!showMore">{{ $t("anyQuestions") }}</span>
          <span v-else>{{ $t("hideAsnwer") }}</span>
          <span class="faq__arrow" :class="{ _active: showMore }"
            ><img src="@/assets/icons/orange-arrow.svg" alt=""
          /></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AccordionItem from "@/components/AccordionItem";
import Accordion from "@/components/AccordionComponent";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Accordion,
    AccordionItem,
  },
  data() {
    return {
      showMore: false,
    };
  },
  computed: {
    ...mapState(["faq"]),
  },
  created() {
    this.fetchFaq();
  },
  methods: {
    ...mapActions(["fetchFaq"]),
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    },
  },
};
</script>

<style lang="scss">
.faq {
  padding: 65px 0;
  background: #E8F1F3;
  @media (max-width: 767.98px) {
    padding: 32px 0;
  }
  &__title {
    color: #FF3D3D !important;
    margin: 0 0 151px 0;
    &::after {
      background-image: url("@/assets/icons/title-icon-orange.svg");
    }
    @media (max-width: 767.98px) {
      margin: 0 0 63px 0;
    }
  }
  &__body {
    background: #ffffff;
    border: 3px solid #46c555;
    border-radius: 60px;
    padding: 32px;
    @media (max-width: 991.98px) {
      padding: 20px 30px 40px;
      border-radius: 30px;
    }
    @media (max-width: 767.98px) {
      border-width: 2px;
      padding: 8px 16px 24px;
      border-radius: 15px;
    }
  }
  &__item {
    border-bottom: 1px solid #e9e9e9;
    padding: 20px 0;
    @media (max-width: 991.98px) {
      padding: 20px 0;
    }
    @media (max-width: 767.98px) {
      padding: 12px 0;
    }
  }
  &__question {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0 20px;
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    line-height: 135.8%;
    letter-spacing: 0.02em;
    color: #FF3D3D;
    transition: color 0.3s ease 0s;
    @media (max-width: 991.98px) {
      font-size: 22px;
    }
    @media (max-width: 767.98px) {
      font-size: 15px;
      gap: 0;
    }
  }

  &__answer {
    font-weight: 400;
    font-size: 24px;
    line-height: 137.5%;
    letter-spacing: 0.02em;
    color: #626262;
    padding: 33px 0 0 0;
    @media (max-width: 767.98px) {
      padding: 18px 0 0 0;
      font-size: 14px;
    }
  }
  &__icon {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    float: right;
    align-items: center;
    justify-content: center;
    transition: transform 0.4s ease 0s;
    img {
      width: 20px;
      height: 14px;
    }
    @media (max-width: 991.98px) {
      min-width: 30px;
      min-height: 30px;
      width: 30px;
      height: 30px;
      img {
        width: 20px;
        height: 13px;
      }
    }
    @media (max-width: 767.98px) {
      min-width: 24px;
      min-height: 24px;
      width: 24px;
      height: 24px;
      img {
        width: 14px;
        height: 7px;
      }
    }
  }
  &__button {
    display: block;
    margin: 40px auto 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 137.5%;
    letter-spacing: 0.02em;
    color: #FF3D3D;
    text-shadow: 2px 2px 15px rgba(32, 158, 47, 0.3);
    @media (max-width: 991.98px) {
      margin: 30px auto 0;
    }
    @media (max-width: 767.98px) {
      margin: 24px auto 0;
      font-size: 15px;
    }
  }
  &__arrow {
    width: 40px;
    height: 40px;
    margin: 5px auto 0;
    display: block;
    transition: all 0.55s ease 0s;
    img {
      width: 12px;
      height: 32px;
    }
    &._active {
      transform: rotate(180deg);
    }
    @media (max-width: 767.98px) {
      width: 15px;
      height: 15px;
      margin: 2.5px auto 0;
      img {
        width: 9px;
        height: 15px;
      }
    }
  }
  .accordion__header {
    &._active {
      .faq__question {
        color: #FF3D3D;
      }
      .faq__icon {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
