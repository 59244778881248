<template>
  <div class="forgot-password">
    <div class="forgot-password__container _container">
      <h1 class="forgot-password__title main-title _blue">
        {{ $t("login.forgotPassword") }}
      </h1>
      <div class="forgot-password__body">
        <Form
          @submit="sendMessage"
          :validation-schema="schema"
          class="forgot-password__form"
          v-slot="{ errors }"
        >
          <div class="forgot-password__item">
            <div class="forgot-password__text">
              {{ $t("login.enterEmail") }}
            </div>
          </div>
          <div class="forgot-password__item">
            <label for="email">Email</label>
            <Field
              @change="showError = false"
              name="email"
              id="email"
              :class="{ _error: errors.hasOwnProperty('email') }"
              type="email"
              v-model="email"
              class="forgot-password__input main-input"
            />
            <transition name="fade">
              <span
                class="error-message"
                v-if="errors.hasOwnProperty('email')"
                >{{ errors.email }}</span
              >
            </transition>
          </div>

          <div class="forgot-password__item">
            <transition name="fade">
              <span class="error-message" v-if="showError">{{
                $t("login.notFoundUser")
              }}</span>
            </transition>
            <button type="submit" class="forgot-password__btn blue-button">
              {{ $t("login.resetPassword") }}
            </button>
          </div>
        </Form>
        <ModalComponent :showModal="showModal" @hideModal="hideModal">
          <div class="ticket-refund__modal refund-modal">
            <div class="refund-modal__icon">
              <img src="../assets/icons/success.svg" alt="" />
            </div>
            <h2 class="refund-modal__title">
              {{ $t("login.messageSuccess") }}
            </h2>
            <div class="refund-modal__text">
              {{ $t("login.messageText") }}
            </div>
          </div>
        </ModalComponent>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapMutations, mapActions } from "vuex";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import ModalComponent from "@/components/ModalComponent";

export default {
  data() {
    const schema = Yup.object().shape({
      email: Yup.string().email().required().label("Email").nullable(),
    });
    return {
      showModal: false,
      showError: false,
      email: null,
      schema,
    };
  },
  components: {
    ModalComponent,
    Field,
    Form,
  },
  methods: {
    hideModal() {
      (this.showModal = false), this.$router.push("/");
    },
    sendMessage() {
      axios
        .post("https://admin.hawaii.kz/api/V1/forgot-password", {
          email: this.email,
        })
        .then((res) => {
          console.log("Success");
          this.email = "";
          this.showModal = true;
        })
        .catch((err) => {
          console.log(err);
          this.showError = true;
        });
    },
  },
};
</script>

<style lang="scss">
.refund-modal {
  flex-direction: column;
}
.forgot-password {
  background: #E8F1F3;
  padding: 50px 0 70px 0;
  @media (max-width: 991.98px) {
    padding: 41px 0;
  }
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0;
  }

  &__title {
    margin: 0 0 100px 0;
    @media (max-width: $tablet + px) {
      margin: 0 0 55px 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 71px 0;
    }
  }

  &__body {
    margin: 0 auto;
    @media (min-width: $tablet + px) {
      max-width: 650px;
      background: #ffffff;
      box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);
      border-radius: 60px;
      padding: 50px 40px;
    }
  }

  &__form {
    margin: 0 0 50px 0;
    @media (max-width: $tablet + px) {
      margin: 0 0 37px 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 24px 0;
    }
  }

  &__item {
    position: relative;
    label {
      display: block;
      margin: 0 0 8px 0;
      font-weight: 400;
      font-size: 20px;
      line-height: 136.7%;
      letter-spacing: 0.02em;
      color: #339CB5;
      @media (max-width: $tablet + px) {
        font-size: 17px;
      }
      @media (max-width: $mobileSmall + px) {
        font-size: 14px;
        margin: 0 0 5px 0;
      }
    }

    &:not(:last-child) {
      margin: 0 0 32px 0;
      @media (max-width: $tablet + px) {
        margin: 0 0 29px 0;
      }
      @media (max-width: $mobileSmall + px) {
        margin: 0 0 28px 0;
      }
    }
  }

  &__input {
    width: 100%;
  }

  &__text {
    font-weight: 400;
    font-size: 20px;
    line-height: 165%;
    letter-spacing: 0.02em;
    color: #2c3131;
    @media (max-width: $tablet + px) {
      font-size: 17px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
    }
  }

  &__bottom {
    text-align: center;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.4s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
