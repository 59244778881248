import { createStore } from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import newsItem from "@/views/NewsItem";

export default createStore({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    events: [],
    info: {},
    footer: {},
    dataId: 0,
    news: [],
    aquapark: [],
    spa: [],
    maps: [],
    faq: [],
    reviews: [],
    tariffs: [],
    rule: [],
    aboutPageId: null,
    aboutPagById: null,
    tickets: [],
    user: {},
    isAuth: false,
    unAuth: false,
    userI: [],
    scrollElement: null,
    paymentInfo: {},
    totalAmount: null,
    tariffId: null,
    ticketId: null,
    cartLength: [],
    lang: localStorage.getItem("lang") || "ru",
  },
  mutations: {
    UPDATE_LANG(state, lang) {
      state.lang = lang;
      localStorage.setItem("lang", lang);
    },
    SET_TARIFFID(state, tariffId) {
      state.tariffId = tariffId;
    },
    SET_TICKETID(state, ticketId) {
      state.ticketId = ticketId;
    },
    SET_TOTAL(state, total) {
      state.totalAmount = total;
    },
    SET_SCROLL(state, scrollElement) {
      state.scrollElement = scrollElement;
    },
    SET_PAYMENT(state, paymentInfo) {
      state.paymentInfo = paymentInfo;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TICKETS(state, tickets) {
      state.tickets = tickets;
    },
    SET_RULE(state, rule) {
      state.rule = rule;
    },
    SET_TARIFFS(state, tarrifs) {
      state.tariffs = tarrifs;
    },
    SET_EVENTS(state, events) {
      state.events = events;
    },
    SET_NEWS(state, news) {
      state.news = news;
    },
    SET_EVENT(state, eventId) {
      state.eventId = eventId;
    },
    SET_INFO(state, info) {
      state.info = info;
    },
    SET_FOOTER(state, footer) {
      state.footer = footer;
    },
    SET_DATA(state, dataId) {
      state.dataId = dataId;
    },
    SET_AQUAPARK(state, aquapark) {
      state.aquapark = aquapark;
    },
    SET_SPA(state, spa) {
      state.spa = spa;
    },
    SET_MAPS(state, maps) {
      state.maps = maps;
    },
    SET_FAQ(state, faq) {
      state.faq = faq;
    },
    SET_REVIEWS(state, reviews) {
      state.reviews = reviews;
    },
    SET_ABOUT(state, aboutPageId) {
      state.aboutPageId = aboutPageId;
    },
    SET_AUTH(state, payload) {
      state.isAuth = payload;
    },
    SET_UN_AUTH(state, resp) {
      state.unAuth = resp;
    },
    setUser(state, userI) {
      state.setUser = userI;
    },
    ADD_TO_CART(state, {product, countValue}) {
      product['count'] = countValue ? countValue : 2;
      let cartList = JSON.parse(localStorage.getItem('cart_products'));
      localStorage.setItem('cart_products', JSON.stringify([product]))
      if (cartList == null) {
        localStorage.setItem('cart_products', JSON.stringify([product]));
        state.cartLength = localStorage.getItem('cart_products');
      } else if (cartList == []) {
        cartList.push(product)
        localStorage.setItem('cart_products', JSON.stringify(cartList))
        state.cartLength = cartList;
      } else if (cartList.find(item => item.id == product.id)) {
        cartList.forEach((item, index) => {
          if (item.id == product.id) {
            cartList.splice(index, 1)
          }
        })
        localStorage.setItem('cart_products', JSON.stringify(cartList))
        state.cartLength = cartList
      } else {
        cartList.push(product)
        state.cartLength = cartList
        localStorage.setItem('cart_products', JSON.stringify(cartList))
      }
    },
    REMOVE_CART(state) {
      state.cartLength = 0;
    },
  },
  getters: {
    getLang: (state) => state.lang,
    data: (state) => (id) => state.footer.dataproc.find((r) => r.id === id),
    eventById: (state) => (id) => {
      return state.events.find((event) => event.slug == id);
    },
    newsById: (state) => (slug) => {
      return state.news.find((newsItem) => newsItem.slug === slug);
    },
    getUnAuth: (state) => state.unAuth,
    getIsAuth: (state) => state.isAuth,
    getUser: (state) => state.setUser,
    getTickets: (state) => state.tickets,
    onTariffs: (state) => {
      return state.tariffs.filter((tariff) => tariff.status === "1");
    },
    GET_CART_LENGTH(state) {
      return state.cartLength
    },
  },

  actions: {
    async fetchTickets({ commit, state }) {
      try {
        const data = await axios.get(
          `https://admin.hawaii.kz/api/V1/user-tickets`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        commit("SET_TICKETS", data.data);
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async fetchEvents({ commit }) {
      try {
        const data = await axios.get(
          "https://admin.hawaii.kz/api/V1/promo"
        );
        commit("SET_EVENTS", data.data.data);
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async fetchFooter({ commit }) {
      try {
        const data = await axios.get(
          "https://admin.hawaii.kz/api/V1/footer"
        );
        commit("SET_FOOTER", data.data);
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async fetchNews({ commit }) {
      try {
        const data = await axios.get(
          "https://admin.hawaii.kz/api/V1/news"
        );
        commit("SET_NEWS", data.data.data);
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async fetchAquapark({ commit }) {
      try {
        const data = await axios.get("V1/aquapark");
        commit("SET_AQUAPARK", data.data);
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async fetchTariffs({ commit }) {
      try {
        const data = await axios.get(
          "https://admin.hawaii.kz/api/V1/ticket-list"
        );
        commit("SET_TARIFFS", data.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchSpa({ commit }) {
      try {
        const data = await axios.get(
          "https://admin.hawaii.kz/api/V1/spa"
        );
        commit("SET_SPA", data.data);
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async fetchMaps({ commit }) {
      try {
        const data = await axios.get(
          "https://admin.hawaii.kz/api/V1/map"
        );
        commit("SET_MAPS", data.data.data);
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async fetchFaq({ commit }) {
      try {
        const data = await axios.get(
          "https://admin.hawaii.kz/api/V1/faq"
        );
        commit("SET_FAQ", data.data.data);
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async fetchReviews({ commit }) {
      try {
        const data = await axios.get("V1/review");
        commit("SET_REVIEWS", data.data.data);
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async fetchRule({ commit }, rule) {
      try {
        const data = await axios.get("V1/footer-page", {
          params: {
            slug: rule,
          },
        });
        commit("SET_RULE", data.data.data);
        console.log(data.data.data);
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    checkAuth({ commit, state }) {
      if (localStorage.getItem("access_token")) {
        commit("SET_AUTH", true);
      }
    },
    async requestUser({ commit }) {
      try {
        const response = await axios.get(`V1/user-info`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        commit("setUser", response.data);
        commit("SET_AUTH", true);
        commit("SET_UN_AUTH", false);
      } catch (error) {
        console.log(error);
        localStorage.removeItem("access_token");
        commit("SET_UN_AUTH", true);
        commit("SET_AUTH", false);
      }
    },
    logout({ commit }) {
      localStorage.removeItem("access_token");
      commit("SET_UN_AUTH", true);
      commit("SET_AUTH", false);
    },
    
  },
  modules: {},
});
